import { Form, FormikErrors, FormikTouched } from 'formik';
import Dates from './components/Dates';
import MainInfo from './components/MainInfo';
import PushNotifications from './components/PushNotifications';
import Rewards from './components/Rewards';
import Story from './components/Story';
import { useDeleteReferralProgramMutation } from 'pages/settingLeads/api/generated/DeleteReferralProgram';
import { useGetReferralProgramLazyQuery } from 'pages/settingLeads/api/generated/GetReferralProgram';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useSettingLeadsStore } from 'pages/settingLeads/store';
import { InitialValues } from './utils/form';
import Modal from 'ui/modal';
import { useGetSchedulerReferralProgramsQuery } from 'pages/settingLeads/api/generated/GetSchedulerReferralPrograms';

interface Props {
  nextProgramId?: string;
  values: InitialValues;
  handleChange: any;
  touched: FormikTouched<any>;
  errors: FormikErrors<InitialValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<InitialValues>>;
  loading: boolean;
}

const Editor = ({ nextProgramId, values, handleChange, touched, errors, setFieldValue, loading }: Props) => {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const { draftId } = useSettingLeadsStore(state => state);
  const [deleteReferralProgram] = useDeleteReferralProgramMutation();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { refetch } = useGetSchedulerReferralProgramsQuery({
    variables: { input: { referralProgramId: settingId ?? '' } },
  });
  const [getReferralProgram, { data }] = useGetReferralProgramLazyQuery();

  useEffect(() => {
    if (settingId) getReferralProgram({ variables: { input: { id: settingId } } });
  }, [settingId, getReferralProgram]);

  const handleDelete = () => {
    if (data)
      deleteReferralProgram({ variables: { input: { id: data.getReferralProgram.referralProgram.id } } }).then(() => {
        setDeleteModalVisible(false);
        refetch();
        navigate(`/offers/leads/setting/${nextProgramId}`);
      });
  };

  return (
    <Form className="blockTemplate">
      <h3 className="blockTitleTemplate">Настройки реферальной программы</h3>

      <MainInfo values={values} handleChange={handleChange} touched={touched} errors={errors} />
      <hr />

      <Rewards values={values} handleChange={handleChange} touched={touched} errors={errors} />
      <hr />

      <Dates
        values={values}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        touched={touched}
        errors={errors}
      />
      <hr />

      <Story draftId={draftId} stories={data?.getReferralProgram.referralProgram.stories} />
      <hr />

      <PushNotifications draftId={draftId} notifications={data?.getReferralProgram.referralProgram.notifyLetters} />
      <hr />

      <div className="flex justify-end gap-5">
        {data &&
          data?.getReferralProgram.referralProgram.status !== 'ACTIVE' &&
          data?.getReferralProgram.referralProgram.status !== 'ARCHIVE' && (
            <button className="btn-reject" type="button" onClick={() => setDeleteModalVisible(true)}>
              Удалить изменения
            </button>
          )}

        {settingId && data?.getReferralProgram.referralProgram.status === 'DRAFT' && (
          <button className="btn-primary" type="submit" disabled={loading}>
            Запланировать изменения
          </button>
        )}
      </div>

      <Modal
        type="delete"
        title="Удалить изменения реферальной программы?"
        open={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={handleDelete}
      >
        Вы уверены, что хотите удалить реферальную программу?
      </Modal>
    </Form>
  );
};

export default Editor;
