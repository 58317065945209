import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { LeadFragmentDoc } from '../../../../lead/api/fragments/generated/Lead.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type getLeadsV2QueryVariables = Types.Exact<{
  input: Types.LeadListInput;
}>;


export type getLeadsV2Query = { __typename?: 'Query', getLeadsV2: { __typename?: 'GetLeadsV2Model', count: number, pages: number, leads: Array<{ __typename?: 'Lead', id: string, createdAt: any, isProcessed: boolean, status: Types.LeadStatus, updatedAt: any, userId: string, date?: any | null, inviteFriendDate?: any | null, referrerPaymentDatetime?: any | null, referralProgram?: { __typename?: 'ReferralProgramEntity', id: string, rewardForTheReferer?: number | null, numberOfGoodShifts?: number | null, name?: string | null } | null, user?: { __typename?: 'User', id: string, phone: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, goodShiftCount?: number | null, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null, referrer?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, phone: string, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } | null } | null }> } };


export const getLeadsV2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getLeadsV2"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLeadsV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Lead"}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}}]}}]}},...LeadFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __usegetLeadsV2Query__
 *
 * To run a query within a React component, call `usegetLeadsV2Query` and pass it any options that fit your needs.
 * When your component renders, `usegetLeadsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usegetLeadsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usegetLeadsV2Query(baseOptions: Apollo.QueryHookOptions<getLeadsV2Query, getLeadsV2QueryVariables> & ({ variables: getLeadsV2QueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<getLeadsV2Query, getLeadsV2QueryVariables>(getLeadsV2Document, options);
      }
export function usegetLeadsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<getLeadsV2Query, getLeadsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<getLeadsV2Query, getLeadsV2QueryVariables>(getLeadsV2Document, options);
        }
export function usegetLeadsV2SuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<getLeadsV2Query, getLeadsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<getLeadsV2Query, getLeadsV2QueryVariables>(getLeadsV2Document, options);
        }
export type getLeadsV2QueryHookResult = ReturnType<typeof usegetLeadsV2Query>;
export type getLeadsV2LazyQueryHookResult = ReturnType<typeof usegetLeadsV2LazyQuery>;
export type getLeadsV2SuspenseQueryHookResult = ReturnType<typeof usegetLeadsV2SuspenseQuery>;
export type getLeadsV2QueryResult = Apollo.QueryResult<getLeadsV2Query, getLeadsV2QueryVariables>;