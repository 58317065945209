import Plus from 'assets/icons/plus';
import ReferralProgram from './components/ReferralProgram';
import { useCreateDraftReferralProgramMutation } from 'pages/settingLeads/api/generated/CreateDraftReferralProgram';
import { useNavigate, useParams } from 'react-router';
import React from 'react';
import { useSettingLeadsStore } from 'pages/settingLeads/store';
import { useGetSchedulerReferralProgramsQuery } from 'pages/settingLeads/api/generated/GetSchedulerReferralPrograms';

const Scheduler = () => {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const setDraftId = useSettingLeadsStore(state => state.setDraftId);
  const { data, refetch } = useGetSchedulerReferralProgramsQuery({
    variables: { input: { referralProgramId: settingId ?? '' } },
  });
  const [createDraft] = useCreateDraftReferralProgramMutation({ variables: { input: { id: settingId ?? '' } } });

  const handleNewChanges = async () => {
    const id = (await createDraft()).data?.createDraftReferralProgram.id;

    id && setDraftId(id);
    navigate(`/offers/leads/setting/${id}`);
    refetch();
  };

  return (
    <>
      <h3 className="blockTitleTemplate">Планировщик</h3>

      <button
        className="flex justify-center items-center gap-3 w-full p-5 border-[1px] border-dashed border-primary rounded-[10px] text-primary"
        onClick={handleNewChanges}
      >
        <span className="inline-flex">
          <Plus />
        </span>
        Запланировать новые изменения
      </button>

      {data?.getSchedulerReferralPrograms.map((program, index, arr) => {
        return (
          <React.Fragment key={program.id}>
            <ReferralProgram program={program} prevProgram={arr[index + 1]} isLastItem={arr.length - 1 === index} />
            <hr />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Scheduler;
