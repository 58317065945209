import MainLayout from 'components/layout/main';
import Editor from './components/Editor';
import Scheduler from './components/Scheduler';
import { useRef, useEffect, useCallback, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { InitialValues, useEditorForm } from './components/Editor/utils/form';
import { useNavigate, useParams } from 'react-router';
import { useSettingLeadsStore } from './store';
import debounce from 'lodash/debounce';
import Modal from 'ui/modal';
import { useGetSchedulerReferralProgramsQuery } from './api/generated/GetSchedulerReferralPrograms';
import { useReferralProgramHeaderMenu } from 'utils/hooks/headerMenu/useReferralProgramHeaderMenu';
import Information from './components/Infofrmation';

const SettingLeads = () => {
  const headerMenu = useReferralProgramHeaderMenu();

  const { settingId } = useParams();
  const navigate = useNavigate();
  const { draftId, setShowPublishModal, showPublishModal, autoSaveState } = useSettingLeadsStore(state => state);
  const { initialValues, onSubmit, validate, loading } = useEditorForm();
  const { data } = useGetSchedulerReferralProgramsQuery({
    variables: { input: { referralProgramId: settingId ?? null } },
  });

  const editorRef = useRef<HTMLDivElement>(null);
  const schedulerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce((values: InitialValues) => {
      onSubmit({ ...values });
    }, 1000),
    [onSubmit],
  );

  const [previousValues, setPreviousValues] = useState<InitialValues>(initialValues);

  const handlePublish = (values: InitialValues) => {
    onSubmit({ ...values, isApproved: true });
    setShowPublishModal(false);
  };

  useEffect(() => {
    if (!settingId && data?.getSchedulerReferralPrograms.length) {
      navigate(`/offers/leads/setting/${data?.getSchedulerReferralPrograms[0]?.id}`);
    }
  }, [settingId, data, navigate]);

  useEffect(() => {
    if (editorRef.current && schedulerRef.current) {
      schedulerRef.current.style.height = `${editorRef.current.clientHeight}px`;
    }
  }, [editorRef.current?.clientHeight, draftId]);

  const FormValuesHandler = () => {
    const { values, dirty } = useFormikContext<InitialValues>();

    if (JSON.stringify(values) !== JSON.stringify(previousValues)) {
      setPreviousValues(values);
      if (data && settingId) {
        const status = data.getSchedulerReferralPrograms.find(program => program.id === settingId)?.status;

        if (dirty && (status === 'DRAFT' || status === 'PLANNED')) {
          debouncedSubmit({ ...values, isApproved: true, isPublication: false });
        }
      }
    }

    return null;
  };

  return (
    <MainLayout
      title="Настройка реферальной программы"
      autoSave={autoSaveState}
      breadCrumbs={[
        { path: '/offers/leads', name: 'Акции' },
        { path: '/offers/leads/setting', name: 'Настройка реферальной программы' },
      ]}
      headerMenu={headerMenu}
    >
      <Information />
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, touched, setFieldValue, errors, handleChange }) => (
          <>
            <FormValuesHandler />
            <div className="grid grid-cols-[3fr_2fr] items-start gap-10 h-full">
              <div ref={editorRef}>
                <Editor
                  nextProgramId={data?.getSchedulerReferralPrograms[1]?.id}
                  values={values}
                  touched={touched}
                  handleChange={handleChange}
                  errors={errors}
                  loading={loading}
                  setFieldValue={setFieldValue}
                />
              </div>

              <div ref={schedulerRef} className="blockTemplate h-full overflow-auto">
                <Scheduler />
              </div>

              <Modal
                title="Сроки проведения пересекаются"
                open={showPublishModal}
                okText="Опубликовать"
                onCancel={() => setShowPublishModal(false)}
                onOk={() => handlePublish(values)}
              >
                Сроки проведения активной бонусной программы или запланированных изменений пересекаются. Вы точно хотите
                опубликовать?
              </Modal>
            </div>
          </>
        )}
      </Formik>
    </MainLayout>
  );
};

export default SettingLeads;
