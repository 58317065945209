import ReferralProgramsPage from 'pages/referralPrograms';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StocksPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/offers/referrals');
  }, [navigate]);
  return <ReferralProgramsPage />;
};

export default StocksPage;
