import TreeSelect from 'antd/es/tree-select';

const PublicationSettings = () => {
  const tProps = {
    placeholder: 'Введите группу или объект',
    style: {
      width: '400px',
    },
  };

  return (
    <div className="blockTemplate mb-6">
      <div className="blockTitleTemplate">Настройки публикации</div>
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <span className="Subtitle2">Группа или объект</span>
          <TreeSelect
            {...tProps}
            disabled
            onChange={() => {}}
            maxTagCount="responsive"
            value=""
            filterTreeNode={(val, node) => {
              return node.name.includes(val);
            }}
          />
        </div>
        <button className="btn-primary self-end">Опубликовать реферальную программу</button>
      </div>
    </div>
  );
};

export default PublicationSettings;
