import dayjs from 'dayjs';
import { EAutoSave } from 'enums/EAutoSave';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import { useEditReferralProgramMutation } from 'pages/settingLeads/api/generated/EditReferralProgram';
import { useGetReferralProgramLazyQuery } from 'pages/settingLeads/api/generated/GetReferralProgram';
import { useGetSchedulerReferralProgramsQuery } from 'pages/settingLeads/api/generated/GetSchedulerReferralPrograms';
import { useSettingLeadsStore } from 'pages/settingLeads/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';

export interface InitialValues {
  id: string;
  name: string;
  description?: string;
  numberOfGoodShifts: number | string;
  rewardForTheReferer: number | string;
  startDate?: DayjsOrNull;
  startTime?: DayjsOrNull;
  isApproved?: boolean;
  isPublication?: boolean;
}

export const useEditorForm = () => {
  const { settingId } = useParams();
  const { draftId, setShowPublishModal } = useSettingLeadsStore(state => state);
  const setAutoSaveState = useSettingLeadsStore(state => state.setAutoSaveState);
  const [editReferralProgram, { loading }] = useEditReferralProgramMutation();
  const { refetch } = useGetSchedulerReferralProgramsQuery({
    variables: { input: { referralProgramId: settingId ?? '' } },
  });

  const [getReferralProgram, { data }] = useGetReferralProgramLazyQuery();

  const initialValues: InitialValues = useMemo(() => {
    return {
      id: settingId ? settingId : draftId ? draftId : '',
      name: data?.getReferralProgram.referralProgram.name || '',
      description: data?.getReferralProgram.referralProgram.description || '',
      numberOfGoodShifts: data?.getReferralProgram.referralProgram.numberOfGoodShifts || '',
      rewardForTheReferer: data?.getReferralProgram.referralProgram.rewardForTheReferer || '',
      startDate: data?.getReferralProgram.referralProgram.startDatetime
        ? dayjs(data.getReferralProgram.referralProgram.startDatetime)
        : null,
      startTime: data?.getReferralProgram.referralProgram.startDatetime
        ? dayjs(data.getReferralProgram.referralProgram.startDatetime)
        : null,
      isApproved: false,
      isPublication: true,
    };
  }, [draftId, data, settingId]);

  const validate = (values: InitialValues) => {
    const errors: any = {};
    if (!values.name.trim()) errors.name = 'Обязательное поле';
    if (!values.numberOfGoodShifts) errors.numberOfGoodShifts = 'Обязательное поле';
    if (!values.startDate) errors.startDate = 'Обязательное поле';
    if (!values.startTime) errors.startTime = 'Обязательное поле';
    if (!values.rewardForTheReferer) errors.rewardForTheReferer = 'Обязательное поле';
    return errors;
  };

  const formatDateTime = (date?: DayjsOrNull, time?: DayjsOrNull): string => {
    if (date && time) {
      return dayjs(date)
        .set('hour', time.hour())
        .set('minute', time.minute())
        .set('second', 0)
        .set('millisecond', 0)
        .toISOString();
    }
    return '';
  };

  useEffect(() => {
    if (settingId) getReferralProgram({ variables: { input: { id: settingId } } });
  }, [settingId, getReferralProgram]);

  useEffect(() => {
    if (loading) setAutoSaveState(EAutoSave.LOADING);

    setAutoSaveState(EAutoSave.SAVED);
  }, [loading, settingId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = useCallback(
    async ({
      startDate,
      startTime,
      name,
      description,
      numberOfGoodShifts,
      rewardForTheReferer,
      isPublication,
      ...rest
    }: InitialValues) => {
      const startDatetime = formatDateTime(startDate, startTime);

      try {
        await editReferralProgram({
          variables: {
            input: {
              ...rest,
              name: name.trim() || null,
              description: description?.trim() ? description.trim() : null,
              numberOfGoodShifts: Number(numberOfGoodShifts) || null,
              rewardForTheReferer: Number(rewardForTheReferer) || null,
              startDatetime: startDatetime || null,
              isPublication,
            },
          },
        });

        if (isPublication) successToast('Реферальная программа опубликована');
        refetch();
        setAutoSaveState(EAutoSave.SAVED);
      } catch (err: any) {
        if (
          err.message.includes(
            'Найдены реферальные программы, которые пересекаются с датами, указанными в этой реферальной программе:',
          )
        ) {
          if (isPublication) setShowPublishModal(true);
        } else {
          errorToast(errorHandler(err));
        }
        setAutoSaveState(EAutoSave.ERROR);
      }
    },
    [refetch, setAutoSaveState, setShowPublishModal, successToast, errorToast, errorHandler], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { initialValues, onSubmit, validate, loading };
};
