import Dates from './components/Dates';
import Requirements from './components/Requirements';
import Rewards from './components/Rewards';
import MainInfo from './components/MainInfo';

const Editor = () => {
  return (
    <div className="blockTemplate">
      <MainInfo values={''} handleChange={() => {}} touched={{}} errors={{}} />
      <hr />

      <Requirements values={''} handleChange={() => {}} touched={{}} errors={{}} />
      <hr />

      <Rewards values={''} handleChange={() => {}} touched={{}} errors={{}} />
      <hr />

      <Dates values={''} setFieldValue={() => {}} handleChange={() => {}} touched={{}} errors={{}} />
    </div>
  );
};

export default Editor;
