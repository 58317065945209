import MainLayout from '../../components/layout/main';
import Editor from './components/Editor';
import PublicationSettings from './components/PublicationSettigns';
import { useReferralProgramHeaderMenu } from '../../utils/hooks/headerMenu/useReferralProgramHeaderMenu';

const CreateReferralProgramPage = () => {
  const headerMenu = useReferralProgramHeaderMenu();

  return (
    <MainLayout
      title="Новая реферальная программа"
      breadCrumbs={[
        { path: '/offers/referrals', name: 'Акции' },
        { path: '/offers/referrals/create', name: 'Новая реферальная программа' },
      ]}
      headerMenu={headerMenu}
    >
      <PublicationSettings />
      <Editor />
    </MainLayout>
  );
};

export default CreateReferralProgramPage;
